import { LogLevel, PublicClientApplication } from '@azure/msal-browser';

// Config object to be passed to Msal on creation
export const msalConfig = {
  auth: {
    clientId: 'd8c1d3c2-2805-4f46-8633-ddf18e46b679',
    authority: 'https://proximaldtx.b2clogin.com/proximaldtx.onmicrosoft.com/B2C_1_signup_signin',
    knownAuthorities: ['proximaldtx.b2clogin.com'],
    redirectUri: 'https://www.proximaldtx.com', // Must be registered as a SPA redirectURI on your app registration
    postLogoutRedirectUri: 'https://www.proximaldtx.com' // Must be registered as a SPA redirectURI on your app registration
  },
  cache: {
    cacheLocation: 'localStorage'
  },
  system: {
      loggerOptions: {
          loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
              if (containsPii) {	
                  return;	
              }
              switch (level) {	
                  case LogLevel.Error:	
                      console.error(message);	
                      return;	
                  case LogLevel.Info:	
                      console.info(message);	
                      return;	
                  case LogLevel.Verbose:	
                      console.debug(message);	
                      return;	
                  case LogLevel.Warning:	
                      console.warn(message);	
                      return;	
                  default:
                      return;
              }
          },
          logLevel: LogLevel.Verbose
      }
  }
};

export const msalInstance = new PublicClientApplication(msalConfig);

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  scopes: ['openid', 'https://proximaldtx.onmicrosoft.com/fb821565-4eee-4d77-ba69-849a4077acc3/User.Free'],
};

export const apiRequest = {
    scopes: ['https://proximaldtx.onmicrosoft.com/fb821565-4eee-4d77-ba69-849a4077acc3/User.Free']
}

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
};