import { defineStore } from 'pinia'
import { TopicModel, ConditionsTemplateModel } from '../types';

interface State {
    topics: TopicModel[],
    topicTypes: string[],
    currentTopic: string,
    conditionsTemplate: ConditionsTemplateModel[],
    currentView: string
}

export const useDefaultStore = defineStore('defaultStore', {
  state: (): State => ({
    topics: [] ,
    topicTypes: [] ,
    currentTopic: '',
    conditionsTemplate: [],
    currentView: 'home'
  }), 
  getters: {
    getTopics: state => {
      return state.topics
    }, 
    getSelectedTopics: state => {
      return state.topics
      .filter(t=> t.selected)
      .map(t => t.topicText)
    }, 
    getCurrentTopic: state => {
      return state.currentTopic
    },
    getTopicTypes: state => {
      return state.topicTypes
    },
    getConditionsTemplate: (state) : ConditionsTemplateModel[] => state.conditionsTemplate,
    getCurrentView: (state): string => state.currentView
  },
  actions: {
    setTopics (data: any) {
      const oldTopics = [...this.topics]
      this.topics = [...data]
      console.log(oldTopics)
      oldTopics.forEach(t => {
        this.updateTopic(t)
      })
    }, 
    clearTopics() {
      this.topics.forEach(t => t.selected = false)
    },
    updateTopic(data: any) {
      const topic = this.topics.find(t => t.id === data.id)
      if (topic != null) {
        topic.selected = data.selected
      } 
    },
    setCurrentTopic(data: any) {
      this.currentTopic = this.topicTypes[data]
    },
    setConditionsTemplate(data: any) {
       this.conditionsTemplate = data
    },
    setCurrentView(data: string) {
      this.currentView = data
    }
  }
})

