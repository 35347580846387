import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Conditions from '@/views/Conditions.vue'
import Home from '@/views/Home.vue'
import Clinical from '@/views/Clinical.vue'
import SignUp from '@/views/SignUp.vue'
import Profile from '@/views/Profile.vue'
import Legal from '@/views/Legal.vue'
import NotFound from '@/views/NotFound.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/Conditions',
    name: 'Conditions',
    component: Conditions
  },
  {
    path: '/Clinical',
    name: 'Clinical',
    component: Clinical
  },
  {
    path: '/signUp',
    name: 'SignUp',
    component: SignUp
  },
  {
    path: '/Legal',
    name: 'Legal',
    component: Legal
  },
  {
    path: '/Profile',
    name: 'Profile',
    component: Profile
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  { 
    path: '/:catchAll(.*)', 
    component: NotFound,
    name: 'NotFound'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
