<template>
    <main class="content">
        <h1>Legal</h1>
        <p class="legal-txt">The user hereby acknowledges that the website at https://proximaldtx.com/ is not a certified medical device and that he or she is entitled only to the status ‘as is’. Users use this website on their own responsibility and at their own risk. Neither the authors nor the University of North Carolina assume any guarantees with respect to the use of the website for a certain purpose. Neither the authors nor the University of North Carolina are liable for damages that ensue from the private use of the website in the dental practice. The authors would be happy to receive any feedback on the website. However, neither the authors nor the University of North Carolina are obligated to provide any kind of support of a technical, medical, or other nature</p>
        <span>NOTE: any use of this application implies that the user has agreed to the terms of use outlined here.</span>
        <div class="spacer"></div>
    </main>
</template>
<style scoped>
.spacer {
    height: 100px;
}
</style>