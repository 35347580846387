import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import './main.css'
import './base-layout.css'
import router from './router'
import { msalPlugin } from "./plugins/msalPlugin";
import { msalInstance } from "./authConfig";
import { AuthenticationResult, EventType } from "@azure/msal-browser";
import { createPinia } from 'pinia'

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
}
msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
});

createApp(App).use(createPinia()).use(msalPlugin, msalInstance).use(router).mount('#app')
